<template>
  <div class="Playback">
    <div class="Playback-title">
      <div class="title">直播间设置-回放</div>
      <div>开启状态：
        <a-switch
            style="margin-right: 10px"
            v-model="isShow"
            @change="switchChange(isShow)"
            checked-children="开"
            un-checked-children="关"
            default-checked />
      </div>
    </div>
    <div class="Playback-content">
      <div style="display: flex;justify-content: space-between">
        <a-button type="primary" @click="addTable">+ 新增</a-button>
        <a-input-search
            v-model="titleVal"
            style="width: 400px;margin-bottom: 20px;"
            placeholder="请输入发言人姓名或内容关键字"
            enter-button="搜索"
            allow-clear
            @search="onSearch"
        />
      </div>
      <a-table
          :columns="columns"
          :data-source="data"
          :row-key="record => record.id"
          :pagination="false">
        <span slot="serialNumber" slot-scope="text, record, index">
          {{index+1}}
        </span>
        <span slot="duration" slot-scope="text, record">
          <span v-if="!record.isTableShow">{{ current_times(record.duration) }}</span>
          <span></span>
        </span>
        <span slot="videoPic" slot-scope="text, record, index">
          <div v-if="!record.isTableShow" style="display: flex;justify-content: center;position: relative">
            <div class="uploadImg" v-if="!record.videoPic" @click="openModelPic(record)">
<!--              <input-->
<!--                  style="width: 120px;height: 80px;opacity: 0;"-->
<!--                  accept="image/*"-->
<!--                  type="file"-->
<!--                  @change="upload('upload_img' + record.id,record,record.id + 'videoPic')"-->
<!--                  :id="'upload_img' + record.id" />-->
            </div>
            <div v-if="record.videoPic" class="upload_image">
              <img
                  style="width: 100%;height:100%"
                  :src="record.videoPic"/>
              <a-icon @click="()=>{record.videoPic = ''}" class="icon" type="close-circle" />
            </div>
             <div v-if="record.videoPic">

            </div>
          </div>
        </span>
        <template
            v-for="col in ['name']"
            :slot="col"
            slot-scope="text, record, index"
        >
          <div :key="col">
            <a-input
                placeholder="请输入视频标题"
                v-if="record.editable"
                style="margin: -5px 0"
                :value="text"
                @change="e => handleChange(e.target.value, record.id, col)"
            />
            <template v-else>
<!--              <a @click="() => edit(record.id)">{{ text?text:'未设置' }}</a>-->
              <a @click="openLink(record.videoUrl)">{{ text?text:'未设置' }}</a>
            </template>
          </div>
        </template>
        <template slot="action" slot-scope="text, record, index">
          <div class="editable-row-operations">
            <span v-if="record.editable">
              <a @click="() => save(record.id,record)">保存</a>
              <a-popconfirm
                  style="margin-left: 5px"
                  title="是否确认取消?"
                  @confirm="() => cancel(record.id,record)">
                <a>取消</a>
              </a-popconfirm>
            </span>
            <span v-else>
               <a @click="upOrDown('up',record.id)" v-show="index !== 0">上移</a>
               <a style="margin-left: 5px;margin-right: 5px" @click="upOrDown('down',record.id)" v-show="index !== data.length -1">下移</a>

               <a-dropdown :disabled="editingKey !== ''">
                 <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                   更多 <a-icon type="down" />
                 </a>
                 <a-menu slot="overlay">
                   <a-menu-item>
                     <a href="javascript:;" @click="() => edit(record.id)">修改</a>
                   </a-menu-item>
                   <a-menu-item>
                      <a-popconfirm
                          title="是否确认删除?"
                          @confirm="() => del(record.id)">
                        <a>删除</a>
                      </a-popconfirm>
                   </a-menu-item>
                 </a-menu>
               </a-dropdown>
            </span>
          </div>
        </template>
      </a-table>
      <videoDialog
          :visible="visible"
          v-on:closeMain="closeMain"
          v-on:checkList="checkList"
      ></videoDialog>
      <pictureDialog
          :visible="visiblePic"
          v-on:closeMain="closeMainPic"
          v-on:checkList="checkListPic">
      </pictureDialog>
    </div>
  </div>
</template>

<script>
import {update} from "@/utils/update";
import {current_times} from '@/utils/current_time'
import {
  delLiveStreamVideos, liveStreamVideosUpDown,
  liveStreamVideos,
  putLiveStreamVideos,
  putStreamFunc, postLiveStreamVideos
} from "@/service/liveManage";
import videoDialog from "@/components/Material/videoDialog";
import pictureDialog from "@/components/Material/pictureDialog";
const columns = [
      {
        title: '序号',
        align:'center',
        width:'80px',
        scopedSlots: { customRender: 'serialNumber' },
      },{
        title: '图片',
        dataIndex: 'videoPic',
        key: 'videoPic',
        align:'center',
        width:'180px',
        scopedSlots: { customRender: 'videoPic' },
      },{
        title: '标题',
        dataIndex: 'name',
        key: 'name',
        align:'center',
        ellipsis: true,
        width:'20%',
        scopedSlots: { customRender: 'name' },
      },{
        title: '视频时长',
        dataIndex: 'duration',
        key: 'duration',
        align:'center',
        width:'100px',
        scopedSlots: { customRender: 'duration' },
      },{
        title: '生成时间',
        dataIndex: 'createdTime',
        key: 'createdTime',
        align:'center',
      },{
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        align:'center',
        width:'150px',
        scopedSlots: { customRender: 'action' },
      },
    ];
const data = [];
export default {
name: "Playback",
  components:{
    videoDialog,
    pictureDialog
  },
  data(){
    return{
      visiblePic:false,
      visible:false,
      videoList:[],
      current_times,
      isShow:null,
      columns,
      data,
      editingKey: '',
      pageNo:1,
      pageSize:9999,
      total:0,
      liveId:null,
      titleVal:'',
      PicList:[],
      tableList:{},
    }
  },
  computed:{
    funListId(){
      return this.$store.state.funList;
    }
  },
  created() {
    this.liveId = this.$route.query.id;
    this.liveChatRecord()
  },
  methods:{
    closeMain(val) {
      this.visible = val;
    },
    closeMainPic(val) {
      this.visiblePic = val;
    },
    openModelPic(record) {
      this.tableList = record;
      this.visiblePic = true;
    },
    checkListPic(val){
      this.PicList = val[0].url;
      let row = this.tableList;
      let data = {
        "id":row.id,
        "videoPic":this.PicList
      }
      this.putList(data);
    },

    //新增弹窗
    addTable(){
      this.visible = true;
    },
    checkList(val) {
      this.videoList = val;
      for (let i = 0; i < this.videoList.length; i++){
        let data = {
          "streamId":this.funListId.streamId,
          "videoManagerId":this.videoList[i].id,
          "videoPic":this.videoList[i].coverImg //图片路径
        }
        this.liveStreamVideos(data)
      }
    },
    async liveStreamVideos(data) {
      const response = await postLiveStreamVideos(data);
      if (response.code === 0) {
        this.$message.success('新增成功！');
        await this.liveChatRecord()
      } else {
        this.$message.warning(response.message);
      }
    },
    openLink(link){
      window.open(link)
    },
    //获取列表
    async liveChatRecord() {
      let data = {
        page_no:this.pageNo,
        page_size:this.pageSize,
        streamId:this.funListId.streamId,
        name:this.titleVal,
      }
      const response = await liveStreamVideos(data)
      if(response.code === 0){
        this.data = response.data;
        this.isShow = response.isShow ? true:false;
        this.cacheData = this.data.map(item => ({ ...item }));
      }else {
        this.$message.warning(response.message);
      }
    },
    switchChange(isShow){
      this.isShow = isShow;
      let data = {
        "id": this.funListId.id, //id
        "streamId": this.funListId.streamId, //直播间id
        "functionCode": this.funListId.functionCode, //功能code值
        "isShow":isShow ? 1:0  //0 关闭 1开启
      }
      this.putStreamFuncs(data)
    },
    // 修改直播间设置功能列表
    async putStreamFuncs(data) {
      const response = await putStreamFunc(data)
      if(response.code === 200){
        this.$message.success("修改成功！");
        await this.liveChatRecord()
      }else {
        this.$message.warning(response.message);
      }
    },
    confirm(){

    },
    onSearch(){
      this.liveChatRecord();
    },
    paginationChange(){

    },
    async upload(inputId,row,idImg){
      let inputDOM = document.getElementById(inputId).files[0]
      if(inputDOM.size < 2086666.24 ){
        const image = await update(inputDOM)
        if(image.code === 0){
          let data = {
            "id":row.id,
            "videoPic":image.data.url
          }
          await this.putList(data);
          // this.$message.success('上传成功')
          //  隐藏上传按钮
          // document.getElementById(idImg).style.opacity = 0;

        } else{
          this.$message.warning('上传失败'+image.message)
        }
      } else{
        this.$message.warning('仅上传2M内图片')
      }
    },
    // 修改列表
    async putList(data) {
      const response = await putLiveStreamVideos(data)
      if(response.code === 0){
        this.$message.success("修改成功！");
        await this.liveChatRecord();
      }else {
        this.$message.warning(response.message);
      }
    },

    handleChange(value, id, column) {
      const newData = [...this.data];
      const target = newData.find(item => id === item.id);
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    edit(id) {
      const newData = [...this.data];
      const target = newData.find(item => id === item.id);
      this.editingKey = id;
      if (target) {
        target.editable = true;
        this.data = newData;
      }
    },
    save(id,row) {
        const newData = [...this.data];
        const newCacheData = [...this.cacheData];
        const target = newData.find(item => id === item.id);
        const targetCache = newCacheData.find(item => id === item.id);
        if (target && targetCache) {
          delete target.editable;
          this.data = newData;
          Object.assign(targetCache, target);
          this.cacheData = newCacheData;
          //保存修改
          let data = {
            "id":id,
            "name":row.name,
          }
          this.putList(data);
        }
        this.editingKey = '';
    },
    cancel(id,record) {
      if(record.isAddSystem){ //新增-取消删除当前数组
        this.data.splice(this.data.length-1,1)
      }else {
        const newData = [...this.data];
        const target = newData.find(item => id === item.id);
        this.editingKey = '';
        if (target) {
          Object.assign(target, this.cacheData.find(item => id === item.id));
          delete target.editable;
          this.data = newData;
        }
      }
    },
    async del(id) {
      const response = await delLiveStreamVideos(id)
      if (response.code === 200) {
        this.$message.success("修改成功！");
        await this.liveChatRecord();
      } else {
        this.$message.warning(response.message);
      }
    },
    async upOrDown(type,id) {
      const response = await liveStreamVideosUpDown(type,id);
      if (response.code === 0) {
        this.$message.success('操作成功！');
        await this.liveChatRecord();
      } else {
        this.$message.warning(response.message);
      }
    },
  }
}
</script>

<style scoped lang="scss">
.Playback {
  width: 100%;
  //border-left: 1px solid #d9d9d9;
  .Playback-title {
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #F5F5F5;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .title {
      font-weight: bold;
      margin-left: 10px;
    }
  }
  .Playback-content{
    width: 98%;
    margin: auto;
    padding-bottom: 40px;
  }
  .uploadImg{
    border: #DDDDDD 2px dashed;
    width: 120px;
    height: 80px;
    background-image: url("../../assets/add.png");
    background-size:100% 100%;
    background-repeat:no-repeat;
    cursor: pointer;
  }
  .upload_image{
    border: #DDDDDD 2px dashed;
    width: 120px;
    height: 80px;
    text-align: center;
  }
  .icon{
    color: red;
    position: absolute;
    left:76%;
    top: 8%;
    cursor: pointer;
  }
}
</style>

<template>
  <div>
    <a-modal
      title="视频素材列表"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
      :width="1000"
    >
      <div class="modalDiv">
        <!-- 左 -->
        <div style="border-right: 2px solid #e9e9e9">
          <div class="menu" id="menu">
            <a-menu mode="inline" v-model="selected" @click="handleClick">
              <a-menu-item key="1"> 全部视频 </a-menu-item>
              <a-menu-item key="-1">默认分类</a-menu-item>
              <a-menu-item
                v-for="(item, index) in topData"
                :key="item.id"
                v-model="typeid"
              >
                {{ item.title }}
              </a-menu-item>
              <a-menu-item>
                <a-input
                  autocomplete="off"
                  id="addMenu"
                  placeholder="请输入"
                  v-show="addInput"
                  v-model="addTitle"
                  @pressEnter="addMenuTitle"
                ></a-input>
              </a-menu-item>
              <!-- <a-menu-item class="addMenu">
              <div @click="addMenu">
                <span>新增栏目</span>
              </div>
            </a-menu-item> -->
            </a-menu>
          </div>
          <div class="addMenu" @click="addMenu">
            <span>新增栏目</span>
          </div>
        </div>
        <!-- 右 -->
        <div class="content-list">
          <!-- 顶部 -->
          <div class="content-list-top">
            <div>
              <a-button
                style="margin-left: 1%; background-color: #1890ff; color: white"
              >
                <a-icon type="vertical-align-top" />
                上传视频

                <input
                  @change="upVideo($event)"
                  id="files"
                  multiple
                  ref="batchImg"
                  style="
                    position: absolute;
                    top: 0;
                    opacity: 0;
                    width: 140px;
                    height: 35px;
                    left: 0;
                  "
                  accept="video/*"
                  type="file"
                />
              </a-button>
            </div>
            <div>
              <a-input-search
                allowClear
                style="width: 250px"
                placeholder="请输入视频名称"
                enter-button="搜索"
                v-model="imgName"
                @search="search"
              />
            </div>
          </div>
          <a-spin :spinning="spin">
            <!-- 视频 -->
            <div class="content-two-list-card">
              <a-row :gutter="16" type="flex" justify="start" align="bottom">
                <div
                  v-if="videoList.length == 0"
                  style="
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                  "
                >
                  <NullDataPrompt message="暂无视频"></NullDataPrompt>
                </div>
                <a-col
                  v-else
                  :span="6"
                  v-for="(item, index) in videoList"
                  :key="item.id"
                  style="margin-bottom: 2%"
                >
                  <label>
                    <div
                      class="item"
                      @mouseenter="showYuan(index)"
                      @mouseleave="unshow"
                      :class="{ selected: item.isChoose }"
                    >
                      <!-- 视频 -->

                      <div style="width: 100%; height: 75%; position: relative">
                        <a
                          ><img
                            :src="item.coverImg"
                            style="width: 100%; height: 100%"
                        /></a>
                        <p
                          style="
                            position: absolute;
                            bottom: -12px;
                            z-index: 99999;
                            margin-left: 10px;
                          "
                        >
                          <a-icon type="play-circle" />
                          {{ item.duration }}
                        </p>
                      </div>
                      <!-- 选择框 -->
                      <div
                        class="checkbox"
                        v-show="index === checkIndex ? true : false"
                      >
                        <!-- <a-checkbox @change="onChange(item)"> </a-checkbox> -->
                        <input
                          v-if="info"
                          type="radio"
                          v-model="checkList1"
                          :value="item"
                          style="width: 15px; height: 15px"
                          id="target"
                          @change="hhh($event, item)"
                        />
                        <input
                          v-else
                          type="checkbox"
                          v-model="checkList"
                          :value="item"
                          style="width: 15px; height: 15px"
                          @change="hhh($event, item)"
                        />
                      </div>

                      <div
                        class="dropdown"
                        v-show="index === selectIndex ? true : false"
                      >
                        <a class="dropdown-button">
                          <a-icon
                            type="ellipsis"
                            style="font-size: 26px; color: rgb(167, 160, 160)"
                          />
                        </a>
                        <div class="dropdown-menu">
                          <label for="addLabel"
                            ><a @click="changeName(index, item)">
                              重命名</a
                            ></label
                          >
                          <a @click="changeCategory(item)">移动分类</a>
                          <a @click="del(item)">删除</a>
                        </div>
                      </div>
                      <!-- 名字 -->
                      <div
                        style="
                          width: 100%;
                          height: 15%;
                          position: absolute;
                          bottom: 20px;
                        "
                      >
                        <div
                          v-if="index == inputshow ? false : true"
                          style="
                       width:98%
                      font-size: 14px;
                      font-weight: 550;
                      color: black;
                      margin-top: 4px;
                      margin-left: 7px;
                        white-space: nowrap;
                       overflow: hidden;
                      text-overflow: ellipsis;
                    "
                        >
                          {{ item.title }}
                        </div>
                        <div
                          v-if="index == inputshow ? true : false"
                          style="width: 100%; height: 50%"
                        >
                          <a-input
                            :placeholder="item.title"
                            @pressEnter="changeimgName(item)"
                            @blur="change"
                            v-model="pictureName"
                            id="addLabel"
                          >
                          </a-input>
                        </div>
                      </div>
                      <!-- 时间 -->
                      <div
                        style="
                          width: 100%;
                          height: 10%;
                          position: absolute;
                          bottom: 0px;
                        "
                      >
                        <p
                          style="
                            font-size: 12px;
                            padding: 0px 7px;
                            color: group;
                          "
                        >
                          {{ item.createdTime }}
                        </p>
                      </div>
                    </div>
                  </label>
                </a-col>
              </a-row>
            </div>

            <!-- 分页 -->
            <div
              style="
                display: block;
                width: 100%;
                height: 50px;
                text-align: center;
              "
            >
              <a-pagination
                :current="curPage"
                :total="total"
                :pageSize="pageSize"
                show-quick-jumper
                @change="PageNOchange"
                style="display: inline-block"
              />
            </div>
          </a-spin>
        </div>
      </div>
      <!-- 移动分组弹窗 -->
      <a-modal
        :visible="visible1"
        title="选择分类"
        @ok="moveOk"
        width="30%"
        @cancel="moveCancel"
        style="position: fixed; z-index: 999999"
      >
        <a-radio-group
          button-style="solid"
          :default-value="categoryId"
          :key="categoryId"
          style="padding: 10px 10px 30px 10px"
        >
          <a-radio-button
            :value="item.id"
            v-for="item in topData"
            :key="item.id"
            @click="handle(item)"
          >
            {{ item.title }}
          </a-radio-button>
        </a-radio-group>
      </a-modal>
      <!-- 删除 -->
      <a-modal
        :visible="delVisible"
        @ok="deleteOk"
        @cancel="delCancel"
        :width="300"
        :height="20"
      >
        <p style="font-size: 18px">确认删除视频？</p>
      </a-modal>
    </a-modal>
  </div>
</template>

<script>
import {
  getManagement,
  AddManagement,
  getVideo,
  changeVideo,
  InsertVideo,
  delVideo,
  getSignature,
  getVideoLink,
} from "../../service/columnmanageapi";
import { postImgList } from "@/service/document_api";
import { update, update_File } from "@/utils/update";
import NullDataPrompt from "../NullDataPrompt.vue";
export default {
  name: "videoDialog",
  components: { NullDataPrompt },
  data() {
    return {
      spin: false,
      visible1: false, //移动分组弹窗
      dialogVisible: false,
      topData: [],
      addTitle: "",
      addInput: false,
      videoList: [],
      curPage: 1, //当前页
      total: 0, //总数
      pageSize: 8,
      // iconList: [],
      typeid: "",
      imgName: "",
      checkIndex: -1,
      checkboxShow: -1, //选框
      selectIndex: -1, //圆孔显示
      boxShow: -1, //操作框显示
      inputshow: -1, //输入框显示
      pictureName: "",
      category: {},
      checkList: [],
      iconList: [],
      vFile: [],
      video: [],
      shiping: {},
      videos: [],
      ship: "",
      signature: "",
      logtime: "",
      categoryId: "",
      selected: ["1"],
      delVisible: false,
      delete: {},
      checkList1: {},
    };
  },
  props: {
    // 接收父组件传递的参数
    visible: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.load();
  },
  methods: {
    // 类型
    load() {
      this.spin = true;
      getManagement({
        type: "video",
        curPage: 1,
        pageSize: 9999,
      }).then((res) => {
        this.spin = false;
        // console.log(res);
        this.topData = res.data;
      });
      getVideo({
        columnId: this.typeid,
        title: this.imgName,
        startTime: this.startTime,
        endTime: this.endTime,
        curPage: this.curPage,
        pageSize: this.pageSize,
      }).then((res) => {
        this.spin = false;
        this.videoList = res.data;
        this.total = res.count;
        this.videoList.forEach((item) => {
          let time = item.duration;
          // console.log("222", item.duration);
          let h = parseInt((time / 60 / 60) % 24);
          h = h < 10 ? "0" + h : h;
          let m = parseInt((time / 60) % 60);
          m = m < 10 ? "0" + m : m;
          let s = parseInt(time % 60);
          s = s < 10 ? "0" + s : s;
          // let time = h + ":" + m + ":" + s;
          let atime = h + ":" + m + ":" + s;
          item.duration = atime;
        });
      });
    },
    handleClick(e) {
      this.curPage = 1;
      this.checkList = [];
      if (e.key === "1") {
        this.typeid = "";
      } else {
        this.typeid = e.key;
      }
      this.load();
    },
    handleCancel() {
      this.videoList.forEach((item) => {
        item.isChoose = false;
      });
      let flag = false;
      this.addInput = false;
      this.$emit("closeMain", flag); //子组件向父组件传值(开关)
      this.selected = ["1"];
      this.typeid = "";
      this.load();
      document.getElementById("menu").scrollTop = 0;
      this.checkList1 = {};
      this.checkList = [];
    },
    handleOk() {
      this.videoList.forEach((item) => {
        item.isChoose = false;
      });
      let flag = false;
      this.$emit("closeMain", flag); //子组件向父组件传值（开关）
      if (this.info == true) {
        this.checkList.push(this.checkList1);
      }
      this.$emit("checkList", this.checkList); //子组件向父组件传值(勾选的视频)
      this.checkList = [];
      this.typeid = "";
      this.selected = ["1"];
      this.load();
      document.getElementById("menu").scrollTop = 0;
    },
    // 新增栏目回调
    addMenuTitle() {
      var input = /^[\s]*$/;
      if (!input.test(this.addTitle)) {
        let data = {
          type: "video",
          title: this.addTitle,
        };
        AddManagement(data).then((res) => {
          //添加栏目回调
          if (res.code == 200) {
            this.load();
            this.$message.success("新增成功");
            // this.visible = false;
            document.getElementById("menu").scrollTop = 0;
            this.addInput = false;
            this.addTitle = "";
            this.$emit("addMenu", data); //子组件向父组件传值（新增的栏目）
            // console.log("data", data);
          } else {
            this.$message.warning("新增失败");
            document.getElementById("menu").scrollTop = 0;
            this.load();
          }
        });
      } else {
        // this.selected = ["1"];
        this.$message.warning("请输入正确的类别");
      }
    },
    // 点击添加栏目
    addMenu() {
      this.addInput = true;
      this.$nextTick(function () {
        document.getElementById("addMenu").focus();
      });
    },
    cancelAddmenu() {
      this.addInput = false;
      this.addTitle = "";
      document.getElementById("menu").scrollTop = 0;
    },
    // 改变页码
    PageNOchange(curPage) {
      this.curPage = curPage;
      this.load();
    },
    // 搜索
    search() {
      this.curPage = 1;
      this.load();
    },
    // 鼠标经过
    showYuan(index) {
      this.selectIndex = index;
      this.checkIndex = index;
    },
    change() {
      this.inputshow = -1;
      this.load();
      this.pictureName = "";
    },
    //鼠标移开
    unshow() {
      this.selectIndex = -1;
      this.checkIndex = -1;
    },
    changeboxShow(index) {
      this.boxShow = index;
    },
    // 重命名
    changeName(index, item) {
      this.pictureName = item.title;
      this.inputshow = index;
      this.$nextTick(function () {
        document.getElementById("addLabel").focus();
      });
    },
    // 获取checked状态
    hhh(e, item) {
      if (this.info) {
        //info===true 时为单选
        this.videoList.forEach((item) => {
          item.isChoose = false;
        });
      }
      if (e.target.checked) {
        item.isChoose = true;
        // item.addClass("selected");
      } else {
        this.checkIndex = -1;
        item.isChoose = false;
        // console.log(item);
      }
    },
    // 输入框确定回调
    changeimgName(item) {
      changeVideo({
        id: item.id,
        title: this.pictureName,
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success("修改成功");
          this.inputshow = -1;
          this.load();
          this.pictureName = "";
        }
      });
    },
    // 打开删除
    del(item) {
      this.delete = item;
      // console.log(item);
      this.delVisible = true;
    },
    // 删除确定回调
    deleteOk() {
      delVideo(this.delete.id).then((res) => {
        if (res.code == 0) {
          this.$message.success("删除成功");
          this.load();
          this.delVisible = false;
        } else {
          this.$message.warning("删除失败");
          this.delVisible = false;
        }
      });
    },
    // 删除取消回调
    delCancel() {
      this.$message.warning("取消删除");
      this.load();
      this.delVisible = false;
    },
    // 点击移动分组回调
    changeCategory(item) {
      this.visible1 = true;
      this.category.id = item.id;
      this.categoryId = item.columnId;
    },
    // 弹出框按钮回调
    handle(item) {
      this.category.columnId = item.id;
    },
    // 弹出框确定
    moveOk() {
      changeVideo({
        id: this.category.id,
        columnId: this.category.columnId,
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success("修改成功");
          this.visible1 = false;
          this.load();
          this.categoryId = "";
        } else {
          this.$message.warning("修改失败");
          this.visible1 = false;
          this.load();
          this.categoryId = "";
        }
      });
    },
    // 弹出框取消
    moveCancel() {
      this.categoryId = "";
      this.visible1 = false;
      this.load();
    },
    // 选择的图片
    onChange(item) {
      this.checkList.push(item);
    },

    // 获取上传文件中的File对象信息
    async upVideo(e) {
      this.spin = true;
      if (e.currentTarget.files) {
        for (const file of e.currentTarget.files) {
          this.ship = file;
          const res1 = await this.yun_getSignature();
          const res2 = await this.uploadVideoFile_toyun();
          this.shiping.title = file.name;
        }
      }
    },
    yun_getSignature() {
      this.disabledVideo = true;
      getSignature().then((res) => {
        if (res.code === 0) {
          this.signature = res.data.signature;
          // console.log("签名", this.signature);
        }
      });
    },
    // 上传腾讯云
    async uploadVideoFile_toyun() {
      this.disabledVideo = true;
      let _this = this;
      const getSignature = () => {
        return _this.signature;
      };
      const tcVod = new _this.$TcVod({
        getSignature: getSignature, // 前面的获取上传签名的函数
      });
      const mediaFile = this.ship;
      this.vUploader = tcVod.upload({
        mediaFile: mediaFile, // 媒体文件（视频或音频或图片），类型为 File
      });
      const res1 = await this.vUploader.done().then((res) => {
        // 上传完成回调
        //视频上传后获取到的视频fileId
        this.shiping.fileId = res.fileId;

        //获取视频url地址
        this.get_video_url(res.fileId);
        this.$message.success("视频文件上传成功");
        this.spin = false;
      });
    },
    // 通过fileId获取视频url
    async get_video_url(videoId) {
      //this.disabledVideo = true;
      const response = await getVideoLink(videoId);
      if (response.code === 0) {
        this.shiping.mediaUrl = response.data.mediaUrl;
        this.getVideoBase64(response.data.mediaUrl);
        let audio = new Audio(response.data.mediaUrl);
        let self = this;
        audio.addEventListener("loadedmetadata", function (e) {
          // 时长
          self.duration = parseInt(audio.duration);
          self.logtime = self.duration;
          self.gettime();
        });
      } else {
        this.$message.warning(response.message);
      }
    },
    gettime() {
      this.shiping.duration = this.logtime;
      this.shiping.columnId = this.typeid;
      this.video.push(this.shiping); //push数组
      // console.log(this.video);
    },
    // 获取视频第一帧图片
    getVideoBase64(url) {
      let _this = this;
      return new Promise(function (resolve, reject) {
        let dataURL = "";
        let video = document.createElement("video");
        video.setAttribute("crossOrigin", "anonymous"); //处理跨域
        video.setAttribute("src", url);
        video.setAttribute("width", 700);
        video.setAttribute("height", 600);
        video.setAttribute("preload", "auto");
        video.addEventListener("loadeddata", function () {
          let canvas = document.createElement("canvas"),
            width = video.width, //canvas的尺寸和图片一样
            height = video.height;
          canvas.width = width;
          canvas.height = height;
          canvas.getContext("2d").drawImage(video, 0, 0, width, height); //绘制canvas
          dataURL = canvas.toDataURL("image/jpeg"); //转换为base64
          _this.base64ToFile(dataURL, "封面");
        });
      });
    },
    // base64图片转file的方法（base64图片, 设置生成file的文件名）
    base64ToFile(base64, fileName) {
      // 将base64按照 , 进行分割 将前缀  与后续内容分隔开
      let data = base64.split(",");
      // 利用正则表达式 从前缀中获取图片的类型信息（image/png、image/jpeg、image/webp等）
      let type = data[0].match(/:(.*?);/)[1];
      // 从图片的类型信息中 获取具体的文件格式后缀（png、jpeg、webp）
      let suffix = type.split("/")[1];
      // 使用atob()对base64数据进行解码  结果是一个文件数据流 以字符串的格式输出
      const bstr = window.atob(data[1]);
      // 获取解码结果字符串的长度
      let n = bstr.length;
      // 根据解码结果字符串的长度创建一个等长的整形数字数组
      // 但在创建时 所有元素初始值都为 0
      const u8arr = new Uint8Array(n);
      // 将整形数组的每个元素填充为解码结果字符串对应位置字符的UTF-16 编码单元
      while (n--) {
        // charCodeAt()：获取给定索引处字符对应的 UTF-16 代码单元
        u8arr[n] = bstr.charCodeAt(n);
      }
      // 利用构造函数创建File文件对象
      // new File(bits, name, options)
      const file = new File([u8arr], `${fileName}.${suffix}`, {
        type: type,
      });
      let inputDOM = file;
      update(inputDOM).then((res) => {
        if (res.code === 0) {
          this.shiping.coverImg = res.data.url;

          InsertVideo(this.video).then((res) => {
            this.load();
            this.video = [];
            // this.disabledVideo = false;
          });
        }
      });
    },
    //点击按钮出现弹窗播放视频
    openVideo(item) {
      this.sphttpurl = item.mediaUrl;
      if (!item.mediaUrl) {
        this.$message.error("暂无视频");
        return;
      }
      this.dialogVisible = true;
      // console.log(this.sphttpurl) 后台返回的视频路径
      this.$nextTick(() => {
        let str = document.createElement("video");
        window.setTimeout(function () {
          tag = document.querySelector("#isDiv").appendChild(str);
        }, 1000);
        str.id = "playVideos";
        str.src = this.sphttpurl;
        str.setAttribute("type", "video/mp4");
        str.setAttribute("controls", "controls");
        str.style.width = "800px";
        str.style.height = "400px";
        str.play();
      });
    },
    //关闭弹出框时，视频关闭播放
    handleClose() {
      const video = document.getElementById("playVideos");
      video.remove();
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.ant-col-6 {
  width: 25%;
}
.modalDiv {
  width: 100%;
  display: flex;
  height: 510px;
  margin-top: -24px;
  margin-left: -24px;

  .menu {
    width: 150px;
    border: none;
    position: relative;
    height: 413px;
    overflow-y: scroll;
  }
  .addMenu {
    position: absolute;
    bottom: 70px;
    left: 15px;
    display: inline-block;
    display: flex;
    width: 11%;
    height: 40px;
    font-size: 17px;
    border: 3px dashed #edeff2;
    cursor: pointer;
    span {
      margin: auto;
      color: #1890ff;
    }
  }
  // .content {
  //   width: 100%;
  //   border-left: 1px solid #e7e7e7;
  //   .content-top {
  //     height: 200px;
  //     border-bottom: 1px solid #e7e7e7;
  //     display: flex;
  //     .top-icon {
  //       width: 10%;
  //       text-align: center;
  //       .icon {
  //         line-height: 200px;
  //         font-size: 40px;
  //         color: #929292;
  //         cursor: pointer;
  //       }
  //     }
  //     .imgList {
  //       width: 80%;
  //       display: flex;
  //       margin-top: 20px;
  //       .imgD {
  //         height: 160px;
  //         width: 150px;
  //         margin-right: 30px;
  //       }
  //     }
  //   }

  // }
}
.content-list {
  margin-right: -24px;
  margin-left: 0.5%;
  width: 100%;
  .content-list-top {
    width: 98%;
    display: flex;
    margin: auto;
    justify-content: space-between;

    margin-left: 2%;
    margin-top: $LineSpacing;
    margin-bottom: $LineSpacing;
  }
  .content-two-list-card {
    width: 98%;
    height: 410px;
    margin-left: 2%;
    position: relative;
  }
}
.item {
  position: relative;
  width: 100%;
  height: 190px;
  display: inline-block;
  border: 1.5px solid #edeff2;
  border-radius: 8px;

  .checkbox {
    position: absolute;
    top: 1.5%;
    left: 1.5%;
  }
  // .yuan {
  //   position: absolute;
  //   top: 1.5%;
  //   right: 1.5%;
  //   width: 30px;
  //   height: 30px;
  //   border-radius: 15px;
  //   background: white;
  //   z-index: 999;
  //   text-align: center;
  //   line-height: 40px;
  //   cursor: pointer;
  // }
  .box {
    position: absolute;
    top: 19%;
    right: 4%;
    width: 40%;
    height: 40%;
    background: #fff;
    z-index: 0;
    border-radius: 5px;
    span {
      display: inline-block;
      background: #fff;
      width: 100%;
      height: 33.4%;
      cursor: pointer;
    }
  }
}
.selected {
  position: relative;
  width: 100%;
  height: 190px;

  display: inline-block;

  border: 1.5px solid #5aaaf5;
  border-radius: 5px;

  .checkbox {
    position: absolute;
    top: 1.5%;
    left: 1.5%;
  }
  // .yuan {
  //   position: absolute;
  //   top: 1.5%;
  //   right: 1.5%;
  //   width: 30px;
  //   height: 30px;
  //   border-radius: 15px;
  //   background: white;
  //   z-index: 0;
  //   text-align: center;
  //   line-height: 40px;
  //   cursor: pointer;
  // }
  .box {
    position: absolute;
    top: 19%;
    right: 4%;
    width: 40%;
    height: 40%;
    background: #fff;
    z-index: 999;
    border-radius: 5px;
    span {
      display: inline-block;
      background: #fff;
      width: 100%;
      height: 33.4%;
      cursor: pointer;
    }
  }
}
.ant-radio-button-wrapper {
  border-radius: 5px;
  border: none;
  &::before {
    display: none; // 隐藏分界线
  }
}
::v-deep .ant-radio-button-wrapper::before {
  display: none;
}
::v-deep .ant-modal-footer {
  margin-top: -24px;
}
/* 按钮样式 */
.dropdown-button {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background: white;
  text-align: center;
  line-height: 40px;
}

/* 下拉菜单 */
.dropdown-menu {
  /* 默认不显示 */
  display: none;
  /* 设置宽度 */
  width: 80px;
  /* 设置边框 */
  // border: 2px solid #e5f1fb;
  margin-top: 35px;
  border-radius: 5px;
  background-color: white;
}
/* 鼠标悬停时，显示菜单区域 */
.dropdown:hover .dropdown-menu {
  display: block;
}
/* 链接样式 */
.dropdown-menu a {
  /* 换行 */
  display: block;
  /* 黑色字体 */
  color: $topNameColor;
  /* 增大间距 */
  padding: 5px 8px;
  /* 去掉下划线 */
  text-decoration: none;
}

/* 整个下拉菜单 */
.dropdown {
  display: inline-block;
  position: absolute;
  top: 2px;
  right: 5px;
}
// ::v-deep .ant-modal-content .ant-modal-header {
//   border-bottom: none !important;
// }

// ::v-deep .ant-modal-content .ant-modal-footer {
//   border-top: none !important;
// }
</style>
